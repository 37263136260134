import { useState, useEffect, useCallback } from 'react';
import { database } from '../../util/firebase';
import { ref, onValue, get, child, set, update } from 'firebase/database';
import { useOutletContext } from 'react-router-dom';
import resultArt from '../../images/result-art.svg';
import expandIcon from '../../images/expand-icon.svg';
import expandIconAlt from '../../images/expand-icon-alt.svg';
import { BsFiletypeCsv, BsFiletypePdf } from "react-icons/bs";
/* import { MdRefresh } from "react-icons/md"; */
import Capstone from './Capstone';
import classes from './Result.module.css';
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Fix CSS file and remove extra CSS
const Result = (props) => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const [expanded, setExpanded] = useState(false);
    const [resultData, setResultData] = useState({});
    const [mostMissedQuestion, setMostMissedQuestion] = useState({ stimuli: '', question: '' });

    const listenToMonitoringGroupHandler = useCallback (() => {
        if(props.result._id) {
            const monitoringGroupRef = ref(database, `monitoring_groups/${props.result._id}`);
            onValue(monitoringGroupRef, (snapshot) => {
                setResultData(snapshot.val());
                
                // Calculate the most missed question from the snapshot data (if available)
                if(snapshot.val().results?.capstones) {
                    let capstone_percentages = [];
                    for(const key in snapshot.val().results.capstones) {
                        for(const secondaryKey in snapshot.val().results.capstones[key]) {
                            const percentage = (snapshot.val().results.capstones[key][secondaryKey].correct_count || 0) / (snapshot.val().results.capstones[key][secondaryKey].total_count)
                            capstone_percentages.push({ stimuli: key, question: secondaryKey, percentage: percentage })
                        }
                    }

                    const lowestPercentageObject = capstone_percentages.reduce((acc, curr) => {
                        if (curr.percentage < acc.percentage) {
                            return curr;
                        }
                        return acc;
                    }, capstone_percentages[0]);

                    // get new data from the database to replace current most missed question data
                    get(child(ref(database), `stimuli/${lowestPercentageObject.stimuli}`)).then((snapshot) => {
                        if (snapshot.exists()) {
                            setMostMissedQuestion({ stimuli: lowestPercentageObject.stimuli, question: lowestPercentageObject.question, data: snapshot.val() });
                        } else {
                            console.log("No data available");
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            });
        } else if (props.result.monitoring_group_ids) {
            for(let i = 0; i < props.result.monitoring_group_ids.length; i++) {
                const monitoringGroupRef = ref(database, `monitoring_groups/${props.result.monitoring_group_ids[i]}`);
                onValue(monitoringGroupRef, (snapshot) => {
                    setResultData(data => {
                        // first, update the invidual pieces of data
                        let newData = { results: {}};
                        if(data && data.individual_data) newData.individual_data = {...data.individual_data}
                        else newData.individual_data = {}
                        newData.individual_data[props.result.monitoring_group_ids[i]] = {}
                        newData.individual_data[props.result.monitoring_group_ids[i]] = snapshot.val();

                        // then update the overall data and return it
                        const mergeResults = (targetTree, sourceTree) => {
                            Object.keys(sourceTree).forEach(key => {
                                if (typeof sourceTree[key] === 'object' && !Array.isArray(sourceTree[key])) {
                                    if (!targetTree[key]) {
                                        targetTree[key] = {};  // Initialize the target key if not present
                                    }
                                    // Recursively merge objects
                                    mergeResults(targetTree[key], sourceTree[key]);
                                } else if (key === 'correct_count' || key === 'total_count') {
                                    // If it's a count, add the values (initialize if not present)
                                    targetTree[key] = (targetTree[key] || 0) + sourceTree[key];
                                }
                            });
                        };
                    
                        // Now merge all the individual results into newData.results
                        Object.values(newData.individual_data).forEach(individualData => {
                            if (individualData && individualData.results) {
                                // Safely merge if results exist
                                mergeResults(newData.results, individualData.results);
                            }
                        });

                        // Calculate the most missed question from the snapshot data (if available)
                    if(newData.results?.capstones) {
                        let capstone_percentages = [];
                        for(const key in newData.results.capstones) {
                            for(const secondaryKey in newData.results.capstones[key]) {
                                const percentage = (newData.results.capstones[key][secondaryKey].correct_count || 0) / (newData.results.capstones[key][secondaryKey].total_count)
                                capstone_percentages.push({ stimuli: key, question: secondaryKey, percentage: percentage })
                            }
                        }

                        const lowestPercentageObject = capstone_percentages.reduce((acc, curr) => {
                            if (curr.percentage < acc.percentage) {
                                return curr;
                            }
                            return acc;
                        }, capstone_percentages[0]);

                        // get new data from the database to replace current most missed question data
                        get(child(ref(database), `stimuli/${lowestPercentageObject.stimuli}`)).then((snap) => {
                            if (snap.exists()) {
                                setMostMissedQuestion({ stimuli: lowestPercentageObject.stimuli, question: lowestPercentageObject.question, data: snap.val() });
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }

                        return newData;
                    });
                });
            }
        }
    }, [props.result._id, props.result.monitoring_group_ids]);

    useEffect(() => {
        if((expanded || props.result.groupedBy) && (!resultData || Object.keys(resultData)?.length === 0)) {
            listenToMonitoringGroupHandler();
        }
    }, [listenToMonitoringGroupHandler, expanded, resultData, props.result.groupedBy]);

    let conceptsAndSkills = [];
    /* if(resultData?.results?.concepts) {
        for(const key in resultData.results.concepts) {
            conceptsAndSkills.push({ _id: key, type: 'concept', percent_correct: Math.round(resultData.results.concepts[key].correct_count / resultData.results.concepts[key].total_count * 100)})
        }
    } */
    if(resultData?.results?.skills) {
        for(const key in resultData.results.skills) {
            conceptsAndSkills.push({ _id: key, type: 'skill', percent_correct: Math.round(resultData.results.skills[key].correct_count / resultData.results.skills[key].total_count * 100)})
        }
    }
    conceptsAndSkills.sort((a, b) => b.percent_correct - a.percent_correct);

    let standards = [];
    if(resultData?.results?.standards) {
        for(const key in resultData.results.standards) {
            standards.push({ _id: key, type: 'standard', percent_correct: Math.round(resultData.results.standards[key].correct_count / resultData.results.standards[key].total_count * 100)})
        }
    }
    standards.sort((a, b) => b.percent_correct - a.percent_correct);

    let bestStandards = [];
    let worstStandards = [];
    if(standards.length > 1 && standards.length <= 3) {
        bestStandards.push(standards[0]);
        worstStandards.push(standards[standards.length - 1]);
    }
    else if(standards.length > 3) {
        bestStandards.push(standards[0]);
        bestStandards.push(standards[1]);
        worstStandards.push(standards[standards.length - 2]);
        worstStandards.push(standards[standards.length - 1]);
    }

    const calculateBackgroundColor = (percent_correct) => {
        // Desired color: #5C60F5
        const targetColor = [92, 96, 245]; // RGB values
    
        // Calculate transparency based on percent_correct
        const maxTransparency = 1; // 100% transparency (fully opaque)
        const minTransparency = 0.38; // 60% transparency (slightly transparent)
    
        const transparency = (percent_correct / 100) * (maxTransparency - minTransparency) + minTransparency;
    
        const redValue = targetColor[0];
        const greenValue = targetColor[1];
        const blueValue = targetColor[2];
    
        return `rgba(${redValue}, ${greenValue}, ${blueValue}, ${transparency})`;
    };

    const calculateWidth = (percent_correct) => {
        const width = Math.floor((100 - (100 - percent_correct)));
        return `calc((100% - 80px) * ${width / 100})`;
    };

    const exportCsv = async () => {
        console.log(resultData);

        set(child(ref(database), `user_accounts/${authUserData.token}/has_exported_result_csv`), true);

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_exported_result_csv');
        } catch (error) {
            console.error('Error logging event:', error);
        }

        const csvData = Object.keys(resultData.attempts).map(key => ({email: '', first_name: resultData.attempts[key].first_name, last_name: resultData.attempts[key].last_name, user_id: resultData.attempts[key].user_id, score: resultData.attempts[key].score || 0}))
        
        // Get emails to attach to the report
        // TODO: Make this SIS_IDs?
        for(let i = 0; i < csvData.length; i++) {
            await get(child(ref(database), `users/${csvData[i].user_id}/email`)).then((snapshot) => {
                if(snapshot.exists()) {
                    csvData[i].email = snapshot.val()
                }
            }).catch((error) => {
                console.error(error);
            });

            delete csvData[i].user_id;
        }

        // Extract column headers from the first object in csvData
        const headers = Object.keys(csvData[0]);

        // Convert array of objects to CSV string
        const csvString = [
            headers.join(','), // Include column headers as the first row
            ...csvData.map(row => headers.map(header => row[header]).join(','))
        ].join('\n');

        // Create a Blob object from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a URL for the Blob object
        const url = URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `${resultData.assessment.name} - ${resultData.group.name} - Results.csv`; // Set the filename for download
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
    };

    let majorMisconceptions = [];
    if(resultData?.results?.misconceptions) {
        for(const key in resultData.results.misconceptions) {
            if(Math.round(resultData.results.misconceptions[key].correct_count / resultData.results.misconceptions[key].total_count * 100) < 51) {
                majorMisconceptions.push({ _id: key, type: 'misconceptions', percent_correct: Math.round(resultData.results.misconceptions[key].correct_count / resultData.results.misconceptions[key].total_count * 100)})
            }
        }
        majorMisconceptions.sort((a, b) => a.percent_correct - b.percent_correct);
        majorMisconceptions = majorMisconceptions.slice(0, 3); // Trim to the first 3 values
    }

    /* const triggerRegrade = () => {
        if(window.confirm('This will regrade every attempt so non-capstone questions have the same weight as capstones (to help raise student scores). This action cannot be undone. Are you sure? ')) {
            console.log('Regrading ' + props.result._id + '...')

            const updates = {};
            updates[`monitoring_groups/${props.result._id}/needs_regrade`] = true;
            update(ref(database), updates)
        };
    }; */

    const expandResult = () => {
        const updates = {};
        updates[`user_accounts/${authUserData.token}/has_expanded_result`] = true;

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_expanded_result');
        } catch (error) {
            console.error('Error logging event:', error);
        }

        update(ref(database), updates);

        setExpanded(true)
    }

    let custom_text = 'Generating...'
    if(conceptsAndSkills?.length > 1) {
        const length = conceptsAndSkills.length;
        custom_text = `Your students showed strengths in "${conceptsAndSkills[0]?._id}"${length > 3 ? ` and "${conceptsAndSkills[1]?._id}"`:''}. However, they stuggled with "${conceptsAndSkills[length - 1]._id}" and could use remediation in this area.`
    }

    return (
        <div className={ `${classes.resultWrapper} ${ expanded ? classes.expanded : '' } ${ props.last ? classes.lastResult : '' }` }>
            <div className={ classes.resultName }>
                {(props.result._id || props.result.groupedBy === 'assessment') ? props.result.assessment_name : '' }
                {props.result._id && ` (${props.result.group_name})`}
                {(props.result.groupedBy === 'group') ? props.result.group_name : '' }
            </div>
            <div className={ `${classes.summarizedFeedback} ${ expanded ? classes.expanded : '' }` }>{ props.result.groupedBy ? custom_text : props.result.feedback_text }</div>
            <div className={ classes.submissions }>{props.result.submission_count} Submission{ props.result.submission_count > 1 && 's'}</div>
            {expanded ?
                <img src={ expandIconAlt } alt='Less...' className={ classes.expandIcon } onClick={() => {setExpanded(false)}} /> :
                <img src={ expandIcon } alt='More...' className={ classes.expandIcon } onClick={expandResult} />
            }
            { expanded &&
                <>
                    { bestStandards.length > 0 &&
                        <>
                            <div className={classes.miniHeader}>Strongest & Weakest Standard{bestStandards.length < 2 && worstStandards.length < 2 ? '' : 's'}</div>
                            <div className={classes.newGraphWrapper}>
                                <div className={classes.graphLabelBarWrapper}>
                                    <div className={classes.newLabel}>{bestStandards[0]._id}</div>
                                    <div className={classes.graphBar} style={{width: calculateWidth(bestStandards[0].percent_correct)}}/>
                                    <div className={classes.graphPercent}>{bestStandards[0].percent_correct}%</div>
                                </div>
                                {
                                    bestStandards.length > 1 &&
                                    <div className={classes.graphLabelBarWrapper}>
                                        <div className={classes.newLabel}>{bestStandards[1]._id}</div>
                                        <div className={classes.graphBar} style={{width: calculateWidth(bestStandards[1].percent_correct)}}/>
                                        <div className={classes.graphPercent}>{bestStandards[1].percent_correct}%</div>
                                    </div>
                                }
                                <div className={classes.graphLabelBarWrapper}>
                                    <div className={classes.newLabel}>{worstStandards[0]._id}</div>
                                    <div className={classes.graphBar} style={{width: calculateWidth(worstStandards[0].percent_correct)}}/>
                                    <div className={classes.graphPercent}>{worstStandards[0].percent_correct}%</div>
                                </div>
                                {
                                    worstStandards.length > 1 &&
                                    <div className={classes.graphLabelBarWrapper}>
                                        <div className={classes.newLabel}>{worstStandards[1]._id}</div>
                                        <div className={classes.graphBar} style={{width: calculateWidth(worstStandards[1].percent_correct)}}/>
                                        <div className={classes.graphPercent}>{worstStandards[1].percent_correct}%</div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    { conceptsAndSkills.length > 0 &&
                        <>
                        <div className={classes.miniHeader}>Skill Mastery Ranking</div>
                            {
                                conceptsAndSkills.map((item, index) =>
                                    <div className={`${classes.conceptOrSkill} ${ index === conceptsAndSkills.length - 1 ? classes.last : '' }`} key={index} style={{backgroundColor: calculateBackgroundColor(item.percent_correct)}}>
                                        <div className={classes.conceptOrSkillPercentage}>{item.percent_correct}%</div>
                                        <div className={classes.conceptOrSkillText}>{item._id}</div>
                                    </div>
                                )
                            }
                        </>
                    }
                    {
                        majorMisconceptions?.length > 0 &&
                        <>
                            <div className={classes.miniHeader}>Major Misconception{majorMisconceptions.length > 1 ? 's' : ''}</div>
                            <ul className={classes.misconceptionList}>
                                {
                                    majorMisconceptions.map((misconception, index) => <li className={`${classes.misconceptionBullet} ${index === majorMisconceptions.length - 1 ? classes.last : ''}`} key={index}>{100 - misconception.percent_correct}% of students think that {misconception._id}.</li>)
                                }
                            </ul>
                        </>
                    }
                    {
                        mostMissedQuestion.stimuli !== "" && 
                        <>
                            <div className={classes.miniHeader}>Most-Missed Capstone</div>
                            <div className={classes.mostMissedWrapper}>
                                <Capstone capstoneData={mostMissedQuestion.data} showOnly={mostMissedQuestion.question} noTopSpace />
                            </div>
                        </>
                    }
                    { !props.result.groupedBy ? 
                        <>
                            <div className={classes.miniHeader}>Export Options</div>
                            <div className={classes.exportWrapper}>
                                <button className={classes.csvExport} onClick={exportCsv}><span className={classes.exportCsvIcon}><BsFiletypeCsv /></span>Export <span className={classes.hideSmall}>&nbsp;Results&nbsp;</span>as CSV</button>
                                <button className={classes.pdfExport} disabled><span className={classes.exportPdfIcon}><BsFiletypePdf /></span>Export <span className={classes.hideSmall}>&nbsp;Results&nbsp;</span>as PDF</button>
                                {/* <button className={classes.triggerRegrade} onClick={triggerRegrade} disabled={resultData?.needs_regrade}><span className={classes.regradeIcon}><MdRefresh /></span>{resultData?.needs_regrade ? 'Regrading...' : 'Regrade with Uniform Weights'}</button> */}
                            </div>
                        </> : <br />
                    }
                </>
            }
            <img src={ resultArt } alt='' className={ classes.resultArt } />
        </div>
    )
}

export default Result;