import classes from './AssessmentHeader.module.css';
import HorizontalLine from '../UI/HorizontalLine';
import { FaUserFriends } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

const AssessmentHeader = (props) => {
    return(
        <>
            { props.assessment &&
                <>
                    <div className={`${classes.header} ${props.assessment.author === 'You' ? classes.own : ''}`}>
                        {props.assessment.name}
                    </div>
                    <div className={`${classes.buttonBar} ${props.assessment.author === 'You' ? classes.own : ''}`}>
                    </div>
                    <div className={`${classes.questions} ${props.assessment.author === 'You' ? classes.own : ''}`}>
                        {props.assessment.capstone_num} Stimul{props.assessment.capstone_num === 1 ? 'us' : 'i'} &<br />{props.assessment.question_num ?? '??'} Question{props.assessment.question_num === 1 ? '' : 's'}
                    </div>
                    <div className={classes.description}>
                        {props.assessment.description}
                    </div>
                    <div className={classes.bottomRow}>
                        <div className={classes.bottomLeft}>
                            {props.assessment.author === 'You' ?
                                <span style={{fontSize: '14px'}}><FaUser /></span> :
                                <FaUserFriends />
                            }
                            &nbsp;<span className={classes.owner}>{props.assessment.author === 'You' ? 'You' : 'Brain Raider'}</span>
                        </div>
                        <div className={classes.bottomRight}>{ props.standards }</div>
                    </div>
                    <HorizontalLine />
                </>
            }
        </>
    )
};

export default AssessmentHeader;