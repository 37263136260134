import { useState, useEffect } from 'react';
import { database } from '../../util/firebase';
import { ref, onValue } from 'firebase/database';
import { MdOutlineAddCircle, MdOutlineRemoveCircle } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import Question from './Question';
import classes from './Capstone.module.css';
import QuestionSimple from './QuestionSimple';

const Capstone = (props) => {
    const [capstoneData, setCapstoneData] = useState(null);

    useEffect(() => {
        if(!props.capstoneData) {
            const capstoneRef = ref(database, `stimuli/${props.capstoneKey}`);
            onValue(capstoneRef, (snapshot) => {setCapstoneData(snapshot.val());});
        } else {
            setCapstoneData(props.capstoneData); // TODO: Maximum update depth exceeded?
        }
    }, [props.capstoneKey, props.capstoneData]);

    const [isMoving, setIsMoving] = useState(false);
    const addClickHandler = () => {
        setIsMoving(true);
        props.onAdd(props.capstoneKey, capstoneData.standards);
        setIsMoving(false);
    };

    const removeClickHandler = () => {
        setIsMoving(true);
        props.onRemove(props.capstoneKey, capstoneData.standards);
        setIsMoving(false);
    };

    const [expandedId, setExpandedId] = useState('');

    const claimExpanded = (id) => {
        setExpandedId(id);
    };

    return(
        <>
        { capstoneData !== null && 
            <div className={ `${classes.stimuliWrapper} ${props.last ? classes.last : ''} ${ props.editMode ? classes.editMode : '' } ${ props.withLines ? classes.withLines : '' } ${props.noTopSpace ? classes.noTopSpace : ''} ${props.ultraSmallEnabled ? classes.ultraSmallEnabled : ''}` }>
                { props.onAdd && 
                    <button className={ classes.actionButton } onClick={ addClickHandler } disabled={isMoving}>
                        <div className={ classes.centerWrapper}>
                            <MdOutlineAddCircle />&nbsp;Add
                        </div>
                    </button>
                }
                { props.onRemove && 
                    <button className={ `${classes.actionButton} ${props.ultraSmallEnabled ? classes.ultraSmallEnabled : ''}` } onClick={ removeClickHandler } disabled={isMoving}>
                        <div className={ classes.centerWrapper}>
                            <MdOutlineRemoveCircle />&nbsp;Remove
                        </div>
                    </button>
                }
                { props.onEdit && props.onDelete && props.onAddQuestion && 
                    <div className={ classes.buttonRow }>
                        <button className={ `${classes.inlineActionButton} ${classes.primary}` } onClick={ props.onAddQuestion.bind(null, props.capstoneKey, capstoneData.image, capstoneData.text) } disabled={isMoving}>
                            <div className={ classes.centerWrapper}>
                                Add Question
                            </div>
                        </button>
                        <button className={ classes.inlineActionButton } onClick={ props.onEdit.bind(null, props.capstoneKey) } disabled={isMoving}>
                            <div className={ classes.centerWrapper}>
                                <FaRegEdit /><span className={classes.hideSmall}>&nbsp;Edit</span>
                            </div>
                        </button>
                        <button className={ classes.inlineActionButton } onClick={ props.onDelete.bind(null, props.capstoneKey) } disabled={isMoving}>
                            <div className={ classes.centerWrapper}>
                                <RiDeleteBin6Line /><span className={classes.hideSmall}>&nbsp;Delete</span>
                            </div>
                        </button>
                    </div>
                }
                {
                    capstoneData.image && <div className={ `${classes.imageWrapper} ${props.ultraSmallEnabled ? classes.ultraSmallEnabled : ''}` }>
                        <img src={ capstoneData.image } alt='' className={ classes.stimuliImage }/>
                    </div>
                }
                <div className={ `${classes.promptWrapper} ${(props.preview || props.bold) ? classes.bold : ''}` }>{props.numbered && props.index + 1 + '. '}{capstoneData.text}</div>
                {
                    !props.preview && !props.showOnly && capstoneData.questions &&
                    Object.keys(capstoneData.questions).sort((a, b) => capstoneData.questions[a].is_capstone - capstoneData.questions[b].is_capstone).filter((key) => !capstoneData.questions[key].archived).map((key) => 
                        <Question key={key} question={{_id: key, _stimulus_id: props.capstoneKey, image: capstoneData.image, stimulus_text: capstoneData.text, ...capstoneData.questions[key]}} expandable={props.canExpandQuestions} claimExpanded={claimExpanded} expandedId={expandedId} onEdit={props.onEditQuestion} onDelete={props.onDeleteQuestion} isAdmin={props.isAdmin} adminResults={props.adminResults?.questions?.hasOwnProperty(key) ? props.adminResults.questions[key] : null}/>
                    )
                }
                {
                    /* props.preview && capstoneData.questions &&
                    Object.keys(capstoneData.questions).sort((a, b) => capstoneData.questions[a].is_capstone - capstoneData.questions[b].is_capstone).filter((key) => !capstoneData.questions[key].archived).map((key) => 
                        Object.keys(capstoneData.questions).indexOf(key) >= Object.keys(capstoneData.questions).length - 3 &&
                        <Question key={key} question={{_id: key, _stimulus_id: props.capstoneKey, image: capstoneData.image, stimulus_text: capstoneData.text, ...capstoneData.questions[key]}} expandable={props.canExpandQuestions} claimExpanded={claimExpanded} expandedId={expandedId} onEdit={props.onEditQuestion} onDelete={props.onDeleteQuestion} isAdmin={props.isAdmin}/>
                    ) */
                }
                {
                    props.preview && capstoneData.questions &&
                    Object.keys(capstoneData.questions).sort((a, b) => capstoneData.questions[a].is_capstone - capstoneData.questions[b].is_capstone).filter((key) => !capstoneData.questions[key].archived).map((key, index) => 
                        Object.keys(capstoneData.questions).indexOf(key) >= Object.keys(capstoneData.questions).length - 3 &&
                        <QuestionSimple key={key} questionData={{ ...capstoneData.questions[key], key: key, stimuliKey: capstoneData.stimuliKey }} last={index === Object.keys(capstoneData.questions).length - 1} />
                    )
                }
                {
                    props.preview && Object.keys(capstoneData.questions).length - 3 > 0 &&
                    <div className={classes.extraText}>...and {Object.keys(capstoneData.questions).length - 3} more similar question{Object.keys(capstoneData.questions).length - 3 === 1 ? '' : 's'}</div>
                }
                {
                    props.showOnly &&
                    <Question question={{_id: props.showOnly, _stimulus_id: props.capstoneKey, image: capstoneData.image, stimulus_text: capstoneData.text, ...capstoneData.questions[props.showOnly]}} claimExpanded={claimExpanded} expandedId={props.showOnly} standalone isAdmin={props.isAdmin} />
                }
            </div>
        }
        </>
    );  
};

export default Capstone;