import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { database } from '../util/firebase';
import { update, ref, increment, onValue } from 'firebase/database';
import { MdArrowBack } from 'react-icons/md';
import LeftColumn from '../components/UI/LeftColumn';
import RightColumn from '../components/UI/RightColumn';
import AssessmentDetail from '../components/Cards/AssessmentDetail';
import ScrollableCard from '../components/UI/ScrollableCard';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import TextAreaInput from '../components/UI/TextAreaInput';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import Button from '../components/UI/Button';
import CheckboxInput from '../components/UI/CheckboxInput';
import SingleColumn from '../components/UI/SingleColumn';
import EmptyBlock from '../components/UI/EmptyBlock';
import Capstone from '../components/Cards/Capstone';

const AssessmentEditor = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

    const [assessmentData, setAssessmentData] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [enteredInfo, setEnteredInfo] = useState({ name: '', description: '', keywords: '', published: false });
    const [enteredInfoErrors, setEnteredInfoErrors] = useState({ name: '', description: '' });
    const [showDetails, setShowDetails] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPersonal, setIsPersonal] = useState(false);

    const { id: assessment_id } = useParams()
    useEffect(() => {
        if(!authUserData) return;

        if('admin' in authUserData?.roles) {
            const assessmentRef = ref(database, `assessments/${assessment_id}`);
            onValue(assessmentRef, (snapshot) => {setAssessmentData(snapshot.val());});
            setHasLoaded(true);
            setIsPersonal(false);
        } else if ('verified_teacher' in authUserData?.roles || 'unverified_teacher' in authUserData?.roles) {
            const assessmentRef = ref(database, `personal_assessments/${authUserData.token}/${assessment_id}`);
            onValue(assessmentRef, (snapshot) => {setAssessmentData(snapshot.val());});
            setHasLoaded(true);
            setIsPersonal(true);
        }
    }, [assessment_id, authUserData]);
    
    const submitChangesClickHandler = async (event) => {
        event.preventDefault();

        let errorsFound = 0;

        // Validate the entered name and description
        if( enteredInfo.name === '' ) {
            setEnteredInfoErrors(errorData => ({...errorData, name: 'Name cannot be blank.'}));
            errorsFound++;
        } else if(enteredInfo.name.includes('.') || enteredInfo.name.includes(',')) {
            setEnteredInfoErrors(errorData => ({...errorData, name: 'Name cannot include punctuation.'}));
            errorsFound++;
        } else {
            setEnteredInfoErrors(errorData => ({...errorData, name: ''}));
        }

        if(enteredInfo.description === '') {
            setEnteredInfoErrors(errorData => ({...errorData, description: 'Description cannot be blank.'}));
            errorsFound++;
        } else {
            setEnteredInfoErrors(errorData => ({...errorData, description: ''}));
        }

        if(errorsFound > 0) {return;}

        setIsSubmitting(true);

        // Update the relevant database fields
        const updates = {};
        if(!isPersonal) {
            updates[`assessments/${assessment_id}/name`] = enteredInfo.name;
            updates[`assessments/${assessment_id}/description`] = enteredInfo.description;
            updates[`assessments/${assessment_id}/keywords`] = enteredInfo.keywords;
            updates[`assessments/${assessment_id}/published`] = enteredInfo.published;
        } else {
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/name`] = enteredInfo.name;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/description`] = enteredInfo.description;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/keywords`] = enteredInfo.keywords;
            /* updates[`personal_assessments/${authUserData.token}/${assessment_id}/published`] = enteredInfo.published; */
        }
        update(ref(database), updates);

        // Reset the form
        setIsSubmitting(false);
        setShowDetails(false);
    };

    const [hasLoadedStimuli, setHasLoadedStimuli] = useState(false);
    const [allStimuliObject, setAllStimuliObject] = useState({});
    useEffect(() => {
        onValue(ref(database, `stimuli`), (snapshot) => {setAllStimuliObject(snapshot.val());});
        setHasLoadedStimuli(true);
    }, []);

    var stimuli = [];
    if (allStimuliObject) {
        Object.keys(allStimuliObject).forEach((key) => {
            if(assessmentData?.stimuli && !(key in assessmentData.stimuli)) {stimuli.push({_id: key, ...allStimuliObject[key]});}
            else if(!assessmentData?.stimuli) {stimuli.push({_id: key, ...allStimuliObject[key]});}})
    };

    const [filterText, setFilterText] = useState('');
    const filterStimuli = () => {
        var filtered_stimuli = [...stimuli];
  
        // Only filter the stimuli if the user has typed in the search bar
        filtered_stimuli = stimuli.filter(stimuli => 
            !stimuli.archived && // Exclude stimuli with 'archived' as true
            (filterText ?
                (stimuli.text.toLowerCase().includes(filterText.toLowerCase()) ||
                (stimuli.questions && Object.keys(stimuli.questions).map(key => stimuli.questions[key].prompt).join(' ').toLowerCase().includes(filterText.toLowerCase())) ||
                (stimuli.keywords && stimuli.keywords.toLowerCase().includes(filterText.toLowerCase())) ||
                (stimuli.standards && JSON.stringify(stimuli.standards).split(')')[1].toLowerCase().includes(filterText.toLowerCase()))) :
                true // Include all stimuli if there's no filter text
                )
            ).filter(stimuli => stimuli.questions)
            .filter(stimuli => Object.keys(stimuli.questions).some(key => !stimuli.questions[key].archived));

        return filtered_stimuli;
    };

    const filteredStimuli = filterStimuli();

    const addStimuliHandler = (stimuli_id, stimuli_standards) => {
        let question_count = 0
        question_count = Object.values(allStimuliObject[stimuli_id]?.questions).filter(item => item.archived !== true).length;

        let updatedStandards = assessmentData.standards ? {...assessmentData.standards} : {};

        Object.keys(stimuli_standards).forEach(state => {
            Object.keys(stimuli_standards[state]).forEach(standard_group => {
                Object.keys(stimuli_standards[state][standard_group]).forEach(code => {
                    if(!updatedStandards[state]) {
                        updatedStandards[state] = { [standard_group]: { [code]: { text: stimuli_standards[state][standard_group][code], count: 1 } } };
                    } else if (updatedStandards[state] && !updatedStandards[state][standard_group]) {
                        updatedStandards[state][standard_group] = { [code]: { text: stimuli_standards[state][standard_group][code], count: 1 } };
                    } else if (updatedStandards[state][standard_group] && !updatedStandards[state][standard_group][code]) {
                        updatedStandards[state][standard_group][code] = {text: stimuli_standards[state][standard_group][code], count: 1};
                    } else {
                        updatedStandards[state][standard_group][code].count++;
                    };
                })
            })
        });

        const updates = {};
        if(!isPersonal) {
            updates[`stimuli/${stimuli_id}/assessmentIDs/${assessment_id}`] = true;
            updates[`assessments/${assessment_id}/stimuli/${stimuli_id}`] = true;
            updates[`assessments/${assessment_id}/capstone_num`] = increment(1);
            updates[`assessments/${assessment_id}/question_num`] = increment(question_count);
            updates[`assessments/${assessment_id}/standards`] = updatedStandards;
        } else {
            updates[`stimuli/${stimuli_id}/personal_assessmentIDs/${authUserData.token}/${assessment_id}`] = true;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/stimuli/${stimuli_id}`] = true;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/capstone_num`] = increment(1);
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/question_num`] = increment(question_count);
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/standards`] = updatedStandards;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/published`] = true;
        }
        update(ref(database), updates);
    };

    const removeStimuliHandler = (stimuli_id, stimuli_standards) => {
        let question_count = 0
        question_count = Object.values(allStimuliObject[stimuli_id]?.questions).filter(item => item.archived !== true).length;

        let updatedStandards = assessmentData.standards ? {...assessmentData.standards} : {};

        Object.keys(stimuli_standards).forEach(state => {
            Object.keys(stimuli_standards[state]).forEach(standard_group => {
                Object.keys(stimuli_standards[state][standard_group]).forEach(code => {
                    updatedStandards[state][standard_group][code].count--;

                    if(updatedStandards[state][standard_group][code].count < 1) {
                        updatedStandards[state][standard_group][code] = null;
                    };
                })
            })
        });

        const updates = {};
        if(!isPersonal) {
            updates[`stimuli/${stimuli_id}/assessmentIDs/${assessment_id}`] = null;
            updates[`assessments/${assessment_id}/stimuli/${stimuli_id}`] = null;
            updates[`assessments/${assessment_id}/capstone_num`] = increment(-1);
            updates[`assessments/${assessment_id}/question_num`] = increment(-question_count);
            updates[`assessments/${assessment_id}/standards`] = updatedStandards;
        } else {
            updates[`stimuli/${stimuli_id}/personal_assessmentIDs/${authUserData.token}/${assessment_id}`] = null;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/stimuli/${stimuli_id}`] = null;
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/capstone_num`] = increment(-1);
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/question_num`] = increment(-question_count);
            updates[`personal_assessments/${authUserData.token}/${assessment_id}/standards`] = updatedStandards;
            if(assessmentData.capstone_num === 1) {
                updates[`personal_assessments/${authUserData.token}/${assessment_id}/published`] = false;
            }
        }

        update(ref(database), updates);
    };

    const deleteAssessmentHandler = () => {
        if(window.confirm('Are you sure?')) {
            const updates = {};
            if(!isPersonal) {
                updates[`assessments/${assessment_id}/archived`] = true;
                /* if(assessmentData.stimuli) {
                    Object.keys(assessmentData?.stimuli).forEach(key => {
                        updates[`stimuli/${key}/assessmentIDs/${assessment_id}`] = null;
                    });
                }; */
            } else {
                updates[`personal_assessments/${authUserData.token}/${assessment_id}/archived`] = true;
            }
            update(ref(database), updates);
            navigate('/assessments');
        };
    };

    return (
        <>
            { authUserData && ('admin' in authUserData?.roles || 'verified_teacher' in authUserData?.roles || 'unverified_teacher' in authUserData?.roles) && hasLoaded && assessmentData && 
                <div style={{ display: 'flex', minHeight: '100%', justifyContent: 'center', flexWrap: 'wrap', minWidth: '100%'}}>
                    <LeftColumn stackable>
                        <PageHeaderText><MdArrowBack onClick={ () => {navigate('/assessments')} } style={{cursor: 'pointer'}}/>&nbsp;Assessment Editor</PageHeaderText>
                        <ScrollableCard show={true}>
                            { hasLoaded && <AssessmentDetail assessment={{...assessmentData, _id: assessment_id, author: isPersonal ? 'You' : ''}} standardsToShow={authUserData?.standards_environment} personal={isPersonal ? authUserData.token : ''} onRemove={removeStimuliHandler} onDelete={deleteAssessmentHandler} onEditDetails={() => {setEnteredInfo({ name: assessmentData.name, description: assessmentData.description, keywords: assessmentData.keywords, _id: assessment_id, published: assessmentData.published }); setEnteredInfoErrors({ name: '', description: '', keywords: '' }); setShowDetails(true);}} /> }
                        </ScrollableCard>
                    </LeftColumn>
                    <RightColumn stackable>
                        <PageHeaderText>&nbsp;</PageHeaderText>
                        <TextInput name='search' leftIcon='search' placeholder='Search stimulus bank...' nospacebefore nospaceafter value={filterText} onChange={ (value) => {setFilterText(value)} } />
                        { Object.keys(allStimuliObject).length > 0 && filteredStimuli.map((stimulus, index) => <Capstone capstoneKey={stimulus._id} capstoneData={stimulus} index={index} key={stimulus._id} last={index === filteredStimuli.length - 1} onAdd={addStimuliHandler} preview withLines />) }
                        { hasLoadedStimuli && filteredStimuli.length === 0 && <EmptyBlock>No questions found.</EmptyBlock>}
                    </RightColumn>
                    <PulloutDrawer show={showDetails} header={'Edit Assessment'} onCancel={() => {setShowDetails(false); setEnteredInfo({ name: '', description: '', keywords: '', _id: '', published: false }); setEnteredInfoErrors({ name: '', description: '', keywords: '' });}}>
                        <form>
                            <PageHeaderText small labelFor='assessment-name'>Name</PageHeaderText>
                            <TextInput name='assessment-name' value={ enteredInfo.name } onChange={ (value) => {setEnteredInfo(assessmentData => ({...assessmentData, name: value})); setEnteredInfoErrors(errorData => ({...errorData, name: ''}));} } error={ enteredInfoErrors.name }/>
                            <PageHeaderText small labelFor='assessment-description'>Description</PageHeaderText>
                            <TextAreaInput name='assessment-description' value={ enteredInfo.description } onChange={ (value) => {setEnteredInfo(assessmentData => ({...assessmentData, description: value})); setEnteredInfoErrors(errorData => ({...errorData, description: ''}));} } error={ enteredInfoErrors.description }/>
                            <PageHeaderText small labelFor='assessment-keywords'>Additional Keyword(s)</PageHeaderText>
                            <TextInput name='assessment-keywords' value={ enteredInfo.keywords } onChange={ (value) => {setEnteredInfo(currentInfo => ({...currentInfo, keywords: value}));} } error={ enteredInfoErrors.keywords }/>
                            { !isPersonal && <CheckboxInput checked={ enteredInfo.published } onChange={ () => {setEnteredInfo(currentInfo => ({...currentInfo, published: !currentInfo.published}));} } last>Make available for assignment</CheckboxInput> }
                            <Button onClick={ submitChangesClickHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        </form>
                    </PulloutDrawer>
                    {/* <ScreenSizeWarning>
                        The Assessment Editor is only available on devices with a screen width of 1000px or greater. Please rotate your device or try again on a larger screen.
                        <InlineButton centered onClick={ () => {navigate('/assessments')} }>Return to Assessments</InlineButton>
                    </ScreenSizeWarning> */}
                </div>
            }
            { (!authUserData || (hasLoaded && !assessmentData && !('admin' in authUserData?.roles))) && 
                <SingleColumn>
                    <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
                </SingleColumn>
            }
            { authUserData && ('admin' in authUserData?.roles) && hasLoaded && !assessmentData && 
                <SingleColumn>
                    <EmptyBlock>This resource no longer exists.</EmptyBlock>
                </SingleColumn>
            }
        </>
    );
};

export default AssessmentEditor;