import SingleColumn from '../components/UI/SingleColumn';
import Group from '../components/Cards/Group';
import PageHeaderText from '../components/UI/PageHeaderText';
import Button from '../components/UI/Button';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import TextInput from '../components/UI/TextInput';
import DropdownInput from '../components/UI/DropdownInput';
import TopRightButton from '../components/UI/TopRightButton';
import EmptyBlock from '../components/UI/EmptyBlock';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { database } from '../util/firebase';
import { get, ref, push, child, update, increment } from 'firebase/database';
import FlexToTiles from '../components/UI/FlexToTiles';
import TextBlock from '../components/UI/TextBlock';
import { getAnalytics, logEvent } from "firebase/analytics";

const GroupsPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

    const [showDrawer, setShowDrawer] = useState(false);
    const [editingGroup, setEditingGroup] = useState({ _id: '', name: '', style: '' })
    const [editingGroupErrors, setEditingGroupErrors] = useState({ name: '', style: '' })
    const [isSubmitting, setIsSubmitting] = useState(false);

    const editingGroupNameChangeHandler = (value) => {
        setEditingGroup(groupData => ({...groupData, name: value}))
    };

    var groups_array = [];
    if (authUserData?.groups) {
        Object.keys(authUserData.groups).forEach((key, index) => {
            if(!authUserData.groups[key].archived) {
                groups_array.push({_id: key, ...authUserData.groups[key]})
            }
        });
        groups_array.sort((a, b) => a.index - b.index);
    };

    const newGroupClickHandler = () => {
        setShowDrawer(true);
        setEditingGroup({ _id: '', name: '', style: '' })
        setEditingGroupErrors({ name: '', style: '' });
    };

    const editGroupClickHandler = (id, name, style) => {
        setShowDrawer(true);
        setEditingGroup({ _id: id, name: name, style: style || '' })
        setEditingGroupErrors({ name: '', style: '' });
    }

    const submitFormHandler = async (event) => {
        event.preventDefault();

        if(editingGroup.name === '') {
            setEditingGroupErrors((errors) => ({...errors, name: 'Group name cannot be blank.'}));
            return;
        };

        setIsSubmitting(true);

        if(editingGroup._id === '') {
            const newGroupKey = push(child(ref(database), 'groups')).key;
            var group_index = 0;
            if(groups_array.length > 0) {group_index = Math.max(...groups_array.map(group => group.index)) + 1;}
            const updates = {};
            updates[`groups/${newGroupKey}/name`] = editingGroup.name;
            updates[`groups/${newGroupKey}/student_count`] = 0;
            updates[`groups/${newGroupKey}/was_auto`] = false;
            updates[`groups/${newGroupKey}/teacher_id`] = authUserData.token;
            updates[`users/${authUserData.token}/groups/${newGroupKey}/name`] = editingGroup.name;
            updates[`users/${authUserData.token}/groups/${newGroupKey}/student_count`] = 0;
            updates[`users/${authUserData.token}/groups/${newGroupKey}/style`] = editingGroup.style;
            updates[`users/${authUserData.token}/groups/${newGroupKey}/index`] = group_index;
            updates[`users/${authUserData.token}/groups/${newGroupKey}/active_assessments`] = 0;
            updates[`user_accounts/${authUserData.token}/has_made_real_group`] = true;

            try {
                const analytics = getAnalytics();
                logEvent(analytics, 'has_made_real_group');
            } catch (error) {
                console.error('Error logging event:', error);
            }

            update(ref(database), updates);
        } else {
            const updates = {};
            updates[`groups/${editingGroup._id}/name`] = editingGroup.name;
            updates[`users/${authUserData.token}/groups/${editingGroup._id}/name`] = editingGroup.name;
            updates[`users/${authUserData.token}/groups/${editingGroup._id}/style`] = editingGroup.style;
            update(ref(database), updates);
        }
        
        setIsSubmitting(false);
        setShowDrawer(false);
        setEditingGroup({ _id: '', name: '', style: '' });
    };

    const archiveGroupClickHandler = async (id) => {
        console.log(groups_array.find(group => group._id === id))
        if(window.confirm('Are you sure?')) {
            const group = groups_array.find(group => group._id === id)
            if(group?.is_demo_group) {
                const updates = {};
                updates[`groups/${id}`] = {};
                updates[`users/${authUserData.token}/groups/${id}`] = {};
                updates[`users/${authUserData.token}/has_demo_group`] = false;
                update(ref(database), updates);
            } else {
                const updates = {};
                updates[`groups/${id}/archived`] = true;
                updates[`users/${authUserData.token}/groups/${id}/archived`] = true;
                update(ref(database), updates);
            }
        };
    };

    const [mergingGroup, setMergingGroup] = useState({ _id: '', name: '' });
    const [destinationGroup, setDestinationGroup] = useState({ _id: '', name: '' })
    const [showSecondaryDrawer, setShowSecondaryDrawer] = useState(false);

    var destinationGroups = []
    if (authUserData?.groups) {
        Object.keys(authUserData.groups).filter(key => !authUserData.groups[key].archived).filter(key => key !== mergingGroup?._id ).forEach((key) => {
            destinationGroups.push({ value: key, text: authUserData.groups[key].name, index: authUserData.groups[key].index });
            destinationGroups.sort((a, b) => a.index - b.index);
        });
    };

    const mergeGroupClickHandler = async (id, name) => {
        setShowSecondaryDrawer(true);
        setMergingGroup({ _id: id, name: name });

        destinationGroups = []
        if (authUserData?.groups) {
            Object.keys(authUserData.groups).filter(key => key !== id ).forEach((key) => {
                destinationGroups.push({ value: key, text: authUserData.groups[key].name, index: authUserData.groups[key].index });
                destinationGroups.sort((a, b) => a.index - b.index);
            });
        };
        
        setDestinationGroup(destinationGroups[0].value);
    };

    const destinationGroupChangeHandler = (value) => {
        setDestinationGroup(value);
    };

    const submitSecondaryFormHandler = (event) => {
        event.preventDefault();
        
        setIsSubmitting(true);
        get(child(ref(database), `groups/${mergingGroup._id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                var student_user_data = {};
                Object.keys(snapshot.val().student_users).forEach((key) => {
                    student_user_data[key] = { first_name: snapshot.val().student_users[key].first_name, last_name: snapshot.val().student_users[key].last_name, auto_group_id: snapshot.val().student_users[key].auto_group_id, auto_group_name: snapshot.val().student_users[key].auto_group_name }
                });
                const student_count = snapshot.val().student_count;

                const updates = {};
                updates[`groups/${mergingGroup._id}`] = [];
                updates[`groups/${destinationGroup}/student_users`] = student_user_data;
                updates[`groups/${destinationGroup}/student_count`] = increment(student_count);
                updates[`users/${authUserData.token}/groups/${destinationGroup}/student_count`] = increment(student_count);
                updates[`users/${authUserData.token}/groups/${mergingGroup._id}`] = [];
                update(ref(database), updates);
            };
        }).catch((error) => {
            console.error(error);
        });

        setIsSubmitting(false);
        setShowSecondaryDrawer(false);
        setMergingGroup({ _id: '', name: '' });
    };

    const [showReorder, setShowReorder] = useState(false);

    const moveUpClickHandler = (id) => {
        const array_index = groups_array.findIndex(p => p._id === id);
        if(array_index !== 0 ) {
            const current_group_index = groups_array[array_index].index;
            const swap_group_index = groups_array[array_index - 1].index;
            const swap_group_id = groups_array[array_index - 1]._id;
            const updates = {};
            updates[`users/${authUserData.token}/groups/${id}/index`] = swap_group_index;
            updates[`users/${authUserData.token}/groups/${swap_group_id}/index`] = current_group_index;
            update(ref(database), updates);
        };
    };

    const moveDownClickHandler = (id) => {
        const array_index = groups_array.findIndex(p => p._id === id);
        if(array_index !== groups_array.length - 1 ) {
            const current_group_index = groups_array[array_index].index;
            const swap_group_index = groups_array[array_index + 1].index;
            const swap_group_id = groups_array[array_index + 1]._id;
            const updates = {};
            updates[`users/${authUserData.token}/groups/${id}/index`] = swap_group_index;
            updates[`users/${authUserData.token}/groups/${swap_group_id}/index`] = current_group_index;
            update(ref(database), updates);
        };
    };

    const isClasslinkRostered = () => {
        if(authUserData?.identity_link === "classlink_rostered" || authUserData?.identify_link === "classlink_rostered") {
            return true;
        };
        return false;
    }

    const demoGroupUsers = [
        {
            first_name: 'Lara',
            last_name: 'Knapp',
            email: 'LaraKnapp@brainraider.com',
            uid: 'Z6wazJENigcHtc6YIWMrry12c733'
        },
        {
            first_name: 'Luna',
            last_name: 'Short',
            email: 'LunaShort@brainraider.com',
            uid: 'dahYggrBinbtKaRpTzazqB7Lwzu1'
        },
        {
            first_name: 'Saeamus',
            last_name: 'Bray',
            email: 'SeamusBray@brainraider.com',
            uid: 'MQkpPxdYgfdiA3sjWUnfMgpQ9Bk1'
        },
        {
            first_name: 'Armani',
            last_name: 'Rush',
            email: 'ArmaniRush@brainraider.com',
            uid: 'B3RNe1qV3EZsPJHxov3Budz8H8n2'
        },
        {
            first_name: 'Aracely',
            last_name: 'Bautista',
            email: 'AracelyBautista@brainraider.com',
            uid: 'xyNvSiSPkGbeuu7T5yenoxV7dEk1'
        },
        {
            first_name: 'Alayna',
            last_name: 'Jordan',
            email: 'AlaynaJordan@brainraider.com',
            uid: 'DM4Rf74Yjcb5wDOGrD1mWuHmu593'
        },
        {
            first_name: 'Gavyn',
            last_name: 'Chang',
            email: 'GavynChang@brainraider.com',
            uid: '7jS4ciNylXf0rdlsZrs3TOKJkX22'
        },
        {
            first_name: 'Kinley',
            last_name: 'Berger',
            email: 'KinleyBerger@brainraider.com',
            uid: 'uCx1wLsOPHQS0rSh7NjrNqmIBWv1'
        },
        {
            first_name: 'Jocelynn',
            last_name: 'Cantrell',
            email: 'JocelynnCantrell@brainraider.com',
            uid: 'jqW1fjFkLqQKuyWFarLJzQeQuIY2'
        },
        {
            first_name: 'Chanel',
            last_name: 'Vaughn',
            email: 'ChanelVaughn@brainraider.com',
            uid: 'suDT5xykI6ZHBAXa28AWsdvUwjD2'
        },
        {
            first_name: 'Jolie',
            last_name: 'Pacheco',
            email: 'JoliePacheco@brainraider.com',
            uid: 'KW039DEPQLXNzbqEtOghIifFwS32'
        },
        {
            first_name: 'Ava',
            last_name: 'Guerra',
            email: 'AvaGuerra@brainraider.com',
            uid: 'JTXb4q7rUMTPHJE2SdhYet1yd983'
        }
    ]

    const [isAddingDemo, setIsAddingDemo] = useState(false)
    const addDemoGroupClickHandler = (event) => {
        event.preventDefault();
        setIsAddingDemo(true);

        const newGroupKey = push(child(ref(database), 'groups')).key;
        var group_index = 0;
        if(groups_array.length > 0) {group_index = Math.max(...groups_array.map(group => group.index)) + 1;}
        const updates = {};
        updates[`groups/${newGroupKey}/is_demo_group`] = true;
        updates[`groups/${newGroupKey}/name`] = "Demo Group";
        updates[`groups/${newGroupKey}/student_count`] = demoGroupUsers.length;
        updates[`groups/${newGroupKey}/was_auto`] = false;
        updates[`groups/${newGroupKey}/teacher_id`] = authUserData.token;
        updates[`users/${authUserData.token}/groups/${newGroupKey}/is_demo_group`] = true;
        updates[`users/${authUserData.token}/groups/${newGroupKey}/name`] = "Demo Group";
        updates[`users/${authUserData.token}/groups/${newGroupKey}/student_count`] = demoGroupUsers.length;
        updates[`users/${authUserData.token}/groups/${newGroupKey}/style`] = "";
        updates[`users/${authUserData.token}/groups/${newGroupKey}/index`] = group_index;
        updates[`users/${authUserData.token}/groups/${newGroupKey}/active_assessments`] = 0;
        updates[`users/${authUserData.token}/has_demo_group`] = true;
        updates[`user_accounts/${authUserData.token}/has_made_demo_group`] = true;

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_made_demo_group');
        } catch (error) {
            console.error('Error logging event:', error);
        }
          

        // also add all the default students to this group
        for(let i = 0; i < demoGroupUsers.length; i++) {
            // TODO: are there any functions where we read that user's data from their node?
            // if not, remove the whole teacher_ids system
            // updates[`users/${demoGroupUsers[i].uid}/teacher_ids/${authUserData.token}`] = true;
            updates[`groups/${newGroupKey}/student_users/${demoGroupUsers[i].uid}`] = { first_name: demoGroupUsers[i].first_name, last_name: demoGroupUsers[i].last_name, auto_group_id: '', auto_group_name: '' };
            updates[`users/${authUserData.token}/student_users/${demoGroupUsers[i].uid}`] = { first_name: demoGroupUsers[i].first_name, last_name: demoGroupUsers[i].last_name };
        }

        update(ref(database), updates);

        setIsAddingDemo(false);
    }

    return (
        <SingleColumn>
            { authUserData && !('student' in authUserData?.roles) &&
            <div>
                <PageHeaderText>My Groups</PageHeaderText>
                { authUserData && authUserData.groups && Object.keys(authUserData.groups).length === 1 && !authUserData.monitoring_groups && <TextBlock><div style={{textAlign: 'center'}}>You made your first group! <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {navigate('/assessments')}}>Browse assessments</span></div></TextBlock>}
                { authUserData && (groups_array?.length === 0 || !authUserData.groups) && <EmptyBlock>Get started by making your first group!</EmptyBlock>}
                { authUserData && authUserData.groups && Object.keys(authUserData.groups).filter(key => !authUserData.groups[key].archived).length > 1 && <TopRightButton icon='reorder' onClick={ () => setShowReorder(value => !value) }/>}
                <FlexToTiles noflex={showReorder}>
                    {
                        groups_array?.map((group, index) =>
                            <Group
                                group={group}
                                key={group._id}
                                onEdit={editGroupClickHandler}
                                onArchive={archiveGroupClickHandler}
                                onMerge={mergeGroupClickHandler}
                                disableMerge={groups_array.length === 1 || isClasslinkRostered() || group.is_demo_group}
                                disableArchive={isClasslinkRostered()}
                                showReorder={showReorder}
                                onMoveUp={moveUpClickHandler}
                                onMoveDown={moveDownClickHandler}
                                last={index === groups_array.length - 1}
                                first={index === 0}
                                isDemo={group.is_demo_group}
                            />)
                    }
                </FlexToTiles>
                <Button confirm onClick={ newGroupClickHandler } disabled={isClasslinkRostered()}>Add New Group</Button>
                { !authUserData?.has_demo_group && <Button onClick={ addDemoGroupClickHandler } disabled={authUserData.has_demo_group || isAddingDemo}>{isAddingDemo ? 'Adding...' : 'Add Demo Group*'}</Button> }
                <TextBlock bigspace gray><br />*A demo group functions just like a regular class except the students are robots ready to automatically take any assessment at any time. Demo groups help you see how Brain Raider's live monitoring and data analysis features work without having to administer an assessment to live students.</TextBlock>
                <PulloutDrawer show={showDrawer} header={editingGroup?._id ? 'Edit Group' : 'Add New Group'} onCancel={() => {setShowDrawer(false); setEditingGroup({ _id: '', name: '', style: '' });}}>
                    <form>
                        <PageHeaderText small labelFor='group-name'>Name</PageHeaderText>
                        <TextInput name='group-name' value={ editingGroup?.name } onChange={ editingGroupNameChangeHandler } error={ editingGroupErrors.name }/>
                        <Button onClick={ submitFormHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                    </form>
                </PulloutDrawer>
                <PulloutDrawer show={showSecondaryDrawer} header={'Move to Group'} onCancel={() => {setShowSecondaryDrawer(false); setMergingGroup({ _id: '', first_name: '', last_name: '', auto_group_id: '', auto_group_name: '' });}}>
                    <form>
                        <PageHeaderText small labelFor='destination-group'>Merge all {mergingGroup?.name} students into...</PageHeaderText>
                        <DropdownInput name='destination-group' value={ destinationGroup } onChange={ destinationGroupChangeHandler } options={ destinationGroups } />
                        <Button onClick={ submitSecondaryFormHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                    </form>
                </PulloutDrawer>
            </div>
            }
            {
                (!authUserData || ('student' in authUserData?.roles)) &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </SingleColumn>
    );
}

export default GroupsPage;